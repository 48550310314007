<template>
	<div :id="'echartDom' + id" :style="{
      width: width,
      height: height,
    }"></div>
</template>
<script>
	import * as echarts from "echarts";
	export default {
		props: {
			id: {
				type: String,
				default: "1",
			},
			options: {
				type: Object,
				default: () => {
					return {};
				},
			},
			width: {
				type: String,
				default: "400px",
			},
			height: {
				type: String,
				default: "400px",
			},
		},
		data() {
			return {
				myChart: "",
			};
		},
		watch: {
			options: {
				deep: true,
				handler(val) {
					this.initOptions();
				},
			},
		},
		mounted() {
			// 基于准备好的dom，初始化echarts实例
			var myChart = echarts.init(document.getElementById("echartDom" + this.id));
			this.myChart = myChart;
			// 绘制图表
			myChart.setOption(this.options);
		},
		methods: {
			initOptions() {
				if (!this.myChart) {
					setTimeout(() => {
						this.initOptions();
					}, 500);
				}
				// 绘制图表
				this.myChart.setOption(this.options);
			},
		},
	};
</script>
