<template>
  <div class="configure" style="width: 100%; padding: 0 20px">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="报表" disabled name="first1">报表</el-tab-pane>
      <el-tab-pane label="校区经营分析" name="first">
        <div class="title">
          <span class="title-ys">数据范围</span>
        </div>
        <div>
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="经办校区">
              <el-radio-group v-model="form.resource">
                <el-radio label="驿都城校区"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="日期范围">
              <el-radio-group v-model="radio1">
                <div class="flex flex-wrap-wrap" style="width: 100%">
                  <div
                    v-for="(item, index) in date"
                    :key="index"
                    class="margin-right-sm"
                  >
                    <el-tooltip :content="item.date" placement="top">
                      <el-radio-button :label="item.name"></el-radio-button>
                    </el-tooltip>
                  </div>
                  <div class="block">
                    <el-date-picker
                      ref="classdatePicker"
                      v-model="value1"
                      type="daterange"
                      range-separator="-"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      size="mini"
                    />
                  </div>
                </div>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <div class="configure">
          <div class="title">
            <span class="title-ys">报表类别</span>
          </div>
          <div>
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="招生概况">
                <el-checkbox-group v-model="form.type">
                  <el-checkbox
                    v-for="(item, index) in dataRange"
                    :key="index"
                    @change="RangeChange(item)"
                    :label="item.name"
                    name="type"
                  ></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="教务概况">
                <el-checkbox-group v-model="form.type">
                  <el-checkbox
                    v-for="(item, index) in jiaoWuSurvey"
                    :key="index"
                    @change="RangeChange(item)"
                    :label="item.name"
                    name="type"
                  ></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="财务概况">
                <el-checkbox-group v-model="form.type">
                  <el-checkbox
                    v-for="(item, index) in financialOverview"
                    :key="index"
                    @change="RangeChange(item)"
                    :label="item.name"
                    name="type"
                  ></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="员工概况">
                <el-checkbox-group v-model="form.type">
                  <el-checkbox
                    v-for="(item, index) in employeeProfile"
                    :key="index"
                    @change="RangeChange(item)"
                    :label="item.name"
                    name="type"
                  ></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <div class="flex justify-content-flexEnd" style="width: 100%">
                <el-button @click="show = true">生成报表</el-button>
              </div>
            </el-form>
          </div>
        </div>
        <div
          v-if="show"
          class="margin-top flex flex-wrap-wrap justify-content-between"
        >
          <div
            v-for="(item, index) in dataRangeChart"
            :key="index"
            class="flex-wrap-wrap chart margin-top"
          >
            <div style="width: 100%; padding: 10px">
              <span>{{ item.name }}</span>
            </div>
            <el-row :gutter="20">
              <el-col :span="8">
                <echart :options="item.option" :id="index + 1"></echart>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="教务报表" name="third">
        <div class="work-title bg-white flex justify-content-center">
          <el-button
            round
            v-for="(item, index) in reportList"
            :key="index"
            :class="{ 'color-box': display_ === item.value }"
            @click="displayList(item)"
            >{{ item.name }}</el-button
          >
        </div>
        <div class="title">
          <span class="title-ys">数据范围</span>
        </div>
        <div v-if="display_ === 1 || display_ === 2 || display_ === 6">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="上课校区" prop="type">
              <el-checkbox-group v-model="form.resource">
                <el-checkbox label="全部" name="type"></el-checkbox>
                <el-checkbox label="驿都城校区" name="type"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="日期范围" prop="date1">
              <el-radio-group v-model="radio1">
                <div class="flex flex-wrap-wrap" style="width: 100%">
                  <div
                    v-for="(item, index) in date"
                    :key="index"
                    class="margin-right-sm"
                  >
                    <el-tooltip :content="item.date" placement="top">
                      <el-radio-button :label="item.name"></el-radio-button>
                    </el-tooltip>
                  </div>
                  <div class="block">
                    <el-date-picker
                      ref="classdatePicker"
                      v-model="value1"
                      type="daterange"
                      range-separator="-"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      size="mini"
                    />
                  </div>
                </div>
              </el-radio-group>
            </el-form-item>
            <div class="flex justify-content-flexEnd" style="width: 100%">
              <el-button @click="show = true">生成报表</el-button>
            </div>
          </el-form>
          <div class="margin-top">
            <as-list :initdataList="datalist">
              <el-table-column
                align="center"
                prop="banji"
                label="班主任姓名"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="name"
                label="带班/一对一数"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="name1"
                label="带班人次"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="text3"
                label="实到人次"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="text5"
                label="应道人次"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="text4"
                label="出勤率"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="text5"
                label="在读实续人次"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="text6"
                label="续费率"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="text7"
                label="升期人次"
              ></el-table-column>

              <el-table-column
                align="center"
                prop="text7"
                label="退费人次"
              ></el-table-column>
            </as-list>
          </div>
        </div>
        <div v-if="display_ === 3">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="类型" prop="type1">
              <el-radio-group v-model="form.resource">
                <el-radio
                  v-for="(item, index) in classType"
                  :key="index"
                  :label="item.name"
                ></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="上课校区" prop="type">
              <el-checkbox-group v-model="form.resource">
                <el-checkbox label="全部" name="type"></el-checkbox>
                <el-checkbox label="驿都城校区" name="type"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="日期范围" prop="date1">
              <el-radio-group v-model="radio1">
                <div class="flex flex-wrap-wrap" style="width: 100%">
                  <div
                    v-for="(item, index) in date"
                    :key="index"
                    class="margin-right-sm"
                  >
                    <el-tooltip :content="item.date" placement="top">
                      <el-radio-button :label="item.name"></el-radio-button>
                    </el-tooltip>
                  </div>
                  <div class="block">
                    <el-date-picker
                      ref="classdatePicker"
                      v-model="value1"
                      type="daterange"
                      range-separator="-"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      size="mini"
                    />
                  </div>
                </div>
              </el-radio-group>
            </el-form-item>
            <div class="flex justify-content-flexEnd" style="width: 100%">
              <el-button @click="show = true">生成报表</el-button>
            </div>
          </el-form>
        </div>
        <div v-if="display_ === 4">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="类型" prop="type1">
              <el-radio-group v-model="form.resource">
                <el-radio
                  v-for="(item, index) in tuition"
                  :key="index"
                  :label="item.name"
                ></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="上课校区" prop="type">
              <el-checkbox-group v-model="form.resource">
                <el-checkbox label="全部" name="type"></el-checkbox>
                <el-checkbox label="驿都城校区" name="type"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="日期范围" prop="date1">
              <el-radio-group v-model="radio1">
                <div class="flex flex-wrap-wrap" style="width: 100%">
                  <div
                    v-for="(item, index) in date"
                    :key="index"
                    class="margin-right-sm"
                  >
                    <el-tooltip :content="item.date" placement="top">
                      <el-radio-button :label="item.name"></el-radio-button>
                    </el-tooltip>
                  </div>
                  <div class="block">
                    <el-date-picker
                      ref="classdatePicker"
                      v-model="value1"
                      type="daterange"
                      range-separator="-"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      size="mini"
                    />
                  </div>
                </div>
              </el-radio-group>
            </el-form-item>
            <div class="flex justify-content-flexEnd" style="width: 100%">
              <el-button @click="show = true">生成报表</el-button>
            </div>
          </el-form>
        </div>
        <div v-if="display_ === 5">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="类型" prop="type1">
              <el-radio-group v-model="form.resource">
                <el-radio
                  v-for="(item, index) in surplus"
                  :key="index"
                  :label="item.name"
                ></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="上课校区" prop="type">
              <el-checkbox-group v-model="form.resource">
                <el-checkbox label="全部" name="type"></el-checkbox>
                <el-checkbox label="驿都城校区" name="type"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>

            <div class="flex justify-content-flexEnd" style="width: 100%">
              <el-button @click="show = true">生成报表</el-button>
            </div>
          </el-form>
        </div>
      </el-tab-pane>
      <el-tab-pane label="财务报表" name="third2">
        <div class="work-title bg-white flex justify-content-center">
          <el-button
            round
            v-for="(item, index) in reportList2"
            :key="index"
            :class="{ 'color-box': display_ === item.value }"
            @click="displayList(item)"
            >{{ item.name }}</el-button
          >
        </div>
        <div class="title">
          <span class="title-ys">数据范围</span>
        </div>
        <div v-if="display_ === 11">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="类型" prop="type1">
              <el-radio-group v-model="form.resource">
                <el-radio
                  v-for="(item, index) in classType"
                  :key="index"
                  :label="item.name"
                ></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="上课校区" prop="type">
              <el-checkbox-group v-model="form.resource">
                <el-checkbox label="全部" name="type"></el-checkbox>
                <el-checkbox label="驿都城校区" name="type"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="日期范围" prop="date1">
              <el-radio-group v-model="radio1">
                <div class="flex flex-wrap-wrap" style="width: 100%">
                  <div
                    v-for="(item, index) in date"
                    :key="index"
                    class="margin-right-sm"
                  >
                    <el-tooltip :content="item.date" placement="top">
                      <el-radio-button :label="item.name"></el-radio-button>
                    </el-tooltip>
                  </div>
                  <div class="block">
                    <el-date-picker
                      ref="classdatePicker"
                      v-model="value1"
                      type="daterange"
                      range-separator="-"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      size="mini"
                    />
                  </div>
                </div>
              </el-radio-group>
            </el-form-item>
            <div class="flex justify-content-flexEnd" style="width: 100%">
              <el-button @click="show = true">生成报表</el-button>
            </div>
          </el-form>
        </div>
        <div v-if="display_ === 12">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="类型" prop="type1">
              <el-radio-group v-model="form.resource">
                <el-radio
                  v-for="(item, index) in classType"
                  :key="index"
                  :label="item.name"
                ></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="上课校区" prop="type">
              <el-checkbox-group v-model="form.resource">
                <el-checkbox label="全部" name="type"></el-checkbox>
                <el-checkbox label="驿都城校区" name="type"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="日期范围" prop="date1">
              <el-radio-group v-model="radio1">
                <div class="flex flex-wrap-wrap" style="width: 100%">
                  <div
                    v-for="(item, index) in date"
                    :key="index"
                    class="margin-right-sm"
                  >
                    <el-tooltip :content="item.date" placement="top">
                      <el-radio-button :label="item.name"></el-radio-button>
                    </el-tooltip>
                  </div>
                  <div class="block">
                    <el-date-picker
                      ref="classdatePicker"
                      v-model="value1"
                      type="daterange"
                      range-separator="-"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      size="mini"
                    />
                  </div>
                </div>
              </el-radio-group>
            </el-form-item>
            <div class="flex justify-content-flexEnd" style="width: 100%">
              <el-button @click="show = true">生成报表</el-button>
            </div>
          </el-form>
        </div>
      </el-tab-pane>
      <el-tab-pane label="教材杂费报表" name="fourth">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="上课校区" prop="type">
            <el-checkbox-group v-model="form.resource">
              <el-checkbox label="全部" name="type"></el-checkbox>
              <el-checkbox label="驿都城校区" name="type"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="日期范围" prop="date1">
            <el-radio-group v-model="radio1">
              <div class="flex flex-wrap-wrap" style="width: 100%">
                <div
                  v-for="(item, index) in date"
                  :key="index"
                  class="margin-right-sm"
                >
                  <el-tooltip :content="item.date" placement="top">
                    <el-radio-button :label="item.name"></el-radio-button>
                  </el-tooltip>
                </div>
                <div class="block">
                  <el-date-picker
                    ref="classdatePicker"
                    v-model="value1"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    size="mini"
                  />
                </div>
              </div>
            </el-radio-group>
          </el-form-item>
          <div class="flex justify-content-flexEnd" style="width: 100%">
            <el-button @click="show = true">生成报表</el-button>
          </div>
        </el-form>
        <div class="margin-top">
          <as-list :initdataList="datalist">
            <el-table-column
              align="center"
              prop="banji"
              label="班主任姓名"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="name"
              label="带班/一对一数"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="name1"
              label="带班人次"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="text3"
              label="实到人次"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="text5"
              label="应道人次"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="text4"
              label="出勤率"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="text5"
              label="在读实续人次"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="text6"
              label="续费率"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="text7"
              label="升期人次"
            ></el-table-column>

            <el-table-column
              align="center"
              prop="text7"
              label="退费人次"
            ></el-table-column>
          </as-list>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import AsList from "@/components/as-list";
import BarY from "@/components/echarts/BarY";
import Echart from "@/components/echarts/Echart";
export default {
  components: { Echart, BarY, AsList },
  data() {
    return {
      datalist: [
        {
          banji: "万豪——易杜区高三",
          name: "周杰伦",
          name1: "周杰",
          text3: "1",
          text4: "0",
          text5: "0",
          text6: "1",
          text7: "0",
        },
      ],
      tuition: [
        {
          name: "按上课校区",
          value: 1,
        },
        {
          name: "按课程",
          value: 2,
        },
        {
          name: "按课程类别",
          value: 3,
        },
        {
          name: "按班级",
          value: 4,
        },
        {
          name: "按科目",
          value: 5,
        },
        {
          name: "按月",
          value: 6,
        },
      ],
      classType: [
        { name: "按上课校区", value: 1 },
        { name: "按课程", value: 2 },
        { name: "按课程类别", value: 3 },
        { name: "按班级", value: 4 },
        { name: "按科目", value: 5 },
        { name: "按教师", value: 6 },
      ],
      surplus: [
        { name: "按上课校区", value: 1 },
        { name: "按课程", value: 2 },
        { name: "按课程类别", value: 3 },
      ],
      reportList: [
        { name: "班主任数据报表", value: 1 },
        { name: "班级数据报表", value: 2 },
        { name: "上课记录报表", value: 3 },
        { name: "学费消耗报表", value: 4 },
        { name: "剩余学费报表", value: 5 },
        { name: "学管师报表", value: 6 },
      ],
      reportList2: [
        { name: "交易流水报表", value: 11 },
        { name: "收支流水报表", value: 22 },
      ],
      rules: {
        // name: [
        //   { required: true, message: '请输入活动名称', trigger: 'blur' },
        //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        // ],
        date1: [
          { required: true, message: "请选择日期范围", trigger: "blur" },
          // { type: 'date', required: true, message: '请选择日期范围', trigger: 'change' }
        ],
        type: [
          { required: true, message: "请选择上课校区", trigger: "blur" },
          // { type: 'array', required: true, message: '请选择上课校区', trigger: 'change' }
        ],
        type1: [
          { required: true, message: "请选择类型", trigger: "blur" },
          // { type: 'array', required: true, message: '请选择上课校区', trigger: 'change' }
        ],
      },
      display_: 1,
      activeName: "first",
      date: [
        { name: "本月", date: "2021-03-02" },
        { name: "上月", date: "2021-03-22" },
        { name: "今日", date: "2021-03-12" },
        { name: "昨日", date: "2021-03-13" },
        { name: "本周", date: "2021-03-14" },
        { name: "上周", date: "2021-03-16" },
        { name: "今年", date: "2021-03-17" },
        { name: "去年", date: "2021-03-18" },
      ],
      show: false,
      dataRangeChart: [], //招生概况统计图列表
      dataRange: [
        {
          name: "新增咨询",
          option: {
            title: {
              text: "",
            },
            tooltip: {},
            // legend: {
            //     data:['销量']
            // },
            xAxis: {
              data: [
                "数据一",
                "数据二",
                "数据三",
                "数据四",
                "数据五",
                "数据六",
              ],
            },
            yAxis: {},
            series: [
              {
                name: "销量",
                type: "bar",
                data: [5, 20, 36, 10, 10, 20],
              },
            ],
          },
        },
        {
          name: "新增咨询数据环比",
          option: {
            title: {
              text: "",
            },
            tooltip: {},
            // legend: {
            //     data:['销量']
            // },
            xAxis: {
              data: [
                "数据一",
                "数据二",
                "数据三",
                "数据四",
                "数据五",
                "数据六",
              ],
            },
            yAxis: {},
            series: [
              {
                name: "销量",
                type: "bar",
                data: [5, 20, 36, 10, 10, 20],
              },
            ],
          },
        },
        {
          name: "招生人次",
          option: {
            title: {
              text: "",
            },
            tooltip: {},
            // legend: {
            //     data:['销量']
            // },
            xAxis: {
              data: [
                "数据一",
                "数据二",
                "数据三",
                "数据四",
                "数据五",
                "数据六",
              ],
            },
            yAxis: {},
            series: [
              {
                name: "销量",
                type: "bar",
                data: [5, 20, 36, 10, 10, 20],
              },
            ],
          },
        },
        {
          name: "报名数据环比",
          option: {
            title: {
              text: "",
            },
            tooltip: {},
            // legend: {
            //     data:['销量']
            // },
            xAxis: {
              data: [
                "数据一",
                "数据二",
                "数据三",
                "数据四",
                "数据五",
                "数据六",
              ],
            },
            yAxis: {},
            series: [
              {
                name: "销量",
                type: "bar",
                data: [5, 20, 36, 10, 10, 20],
              },
            ],
          },
        },
        {
          name: "报名校区对比",
          option: {
            title: {
              text: "",
            },
            tooltip: {},
            // legend: {
            //     data:['销量']
            // },
            xAxis: {
              data: [
                "数据一",
                "数据二",
                "数据三",
                "数据四",
                "数据五",
                "数据六",
              ],
            },
            yAxis: {},
            series: [
              {
                name: "销量",
                type: "bar",
                data: [5, 20, 36, 10, 10, 20],
              },
            ],
          },
        },
        {
          name: "应收成交额",
          option: {
            title: {
              text: "",
            },
            tooltip: {},
            // legend: {
            //     data:['销量']
            // },
            xAxis: {
              data: [
                "数据一",
                "数据二",
                "数据三",
                "数据四",
                "数据五",
                "数据六",
              ],
            },
            yAxis: {},
            series: [
              {
                name: "销量",
                type: "bar",
                data: [5, 20, 36, 10, 10, 20],
              },
            ],
          },
        },
        {
          name: "应收数据环比",
          option: {
            title: {
              text: "",
            },
            tooltip: {},
            // legend: {
            //     data:['销量']
            // },
            xAxis: {
              data: [
                "数据一",
                "数据二",
                "数据三",
                "数据四",
                "数据五",
                "数据六",
              ],
            },
            yAxis: {},
            series: [
              {
                name: "销量",
                type: "bar",
                data: [5, 20, 36, 10, 10, 20],
              },
            ],
          },
        },
        {
          name: "实收成交额",
          option: {
            title: {
              text: "",
            },
            tooltip: {},
            // legend: {
            //     data:['销量']
            // },
            xAxis: {
              data: [
                "数据一",
                "数据二",
                "数据三",
                "数据四",
                "数据五",
                "数据六",
              ],
            },
            yAxis: {},
            series: [
              {
                name: "销量",
                type: "bar",
                data: [5, 20, 36, 10, 10, 20],
              },
            ],
          },
        },
        {
          name: "实收数据环比",
          option: {
            title: {
              text: "",
            },
            tooltip: {},
            // legend: {
            //     data:['销量']
            // },
            xAxis: {
              data: [
                "数据一",
                "数据二",
                "数据三",
                "数据四",
                "数据五",
                "数据六",
              ],
            },
            yAxis: {},
            series: [
              {
                name: "销量",
                type: "bar",
                data: [5, 20, 36, 10, 10, 20],
              },
            ],
          },
        },
        {
          name: "实收校区对比",
          option: {
            title: {
              text: "",
            },
            tooltip: {},
            // legend: {
            //     data:['销量']
            // },
            xAxis: {
              data: [
                "数据一",
                "数据二",
                "数据三",
                "数据四",
                "数据五",
                "数据六",
              ],
            },
            yAxis: {},
            series: [
              {
                name: "销量",
                type: "bar",
                data: [5, 20, 36, 10, 10, 20],
              },
            ],
          },
        },
        {
          name: "教材杂费收入金额",
          option: {
            title: {
              text: "",
            },
            tooltip: {},
            // legend: {
            //     data:['销量']
            // },
            xAxis: {
              data: [
                "数据一",
                "数据二",
                "数据三",
                "数据四",
                "数据五",
                "数据六",
              ],
            },
            yAxis: {},
            series: [
              {
                name: "销量",
                type: "bar",
                data: [5, 20, 36, 10, 10, 20],
              },
            ],
          },
        },
        {
          name: "退费金额",
          option: {
            title: {
              text: "",
            },
            tooltip: {},
            // legend: {
            //     data:['销量']
            // },
            xAxis: {
              data: [
                "数据一",
                "数据二",
                "数据三",
                "数据四",
                "数据五",
                "数据六",
              ],
            },
            yAxis: {},
            series: [
              {
                name: "销量",
                type: "bar",
                data: [5, 20, 36, 10, 10, 20],
              },
            ],
          },
        },
        {
          name: "学员账户金额",
          option: {
            title: {
              text: "",
            },
            tooltip: {},
            // legend: {
            //     data:['销量']
            // },
            xAxis: {
              data: [
                "数据一",
                "数据二",
                "数据三",
                "数据四",
                "数据五",
                "数据六",
              ],
            },
            yAxis: {},
            series: [
              {
                name: "销量",
                type: "bar",
                data: [5, 20, 36, 10, 10, 20],
              },
            ],
          },
        },
      ], //招生概况
      jiaoWuSurvey: [
        {
          name: "学员就读",
          option: {
            title: {
              text: "",
            },
            tooltip: {},
            // legend: {
            //     data:['销量']
            // },
            xAxis: {
              data: [
                "数据一",
                "数据二",
                "数据三",
                "数据四",
                "数据五",
                "数据六",
              ],
            },
            yAxis: {},
            series: [
              {
                name: "销量",
                type: "bar",
                data: [5, 20, 36, 10, 10, 20],
              },
            ],
          },
        },
        {
          name: "学员消费",
          option: {
            title: {
              text: "",
            },
            tooltip: {},
            // legend: {
            //     data:['销量']
            // },
            xAxis: {
              data: [
                "数据一",
                "数据二",
                "数据三",
                "数据四",
                "数据五",
                "数据六",
              ],
            },
            yAxis: {},
            series: [
              {
                name: "销量",
                type: "bar",
                data: [5, 20, 36, 10, 10, 20],
              },
            ],
          },
        },
      ], //教务概况

      financialOverview: [
        {
          name: "财务概况",
          option: {
            title: {
              text: "",
            },
            tooltip: {},
            // legend: {
            //     data:['销量']
            // },
            xAxis: {
              data: [
                "数据一",
                "数据二",
                "数据三",
                "数据四",
                "数据五",
                "数据六",
              ],
            },
            yAxis: {},
            series: [
              {
                name: "销量",
                type: "bar",
                data: [5, 20, 36, 10, 10, 20],
              },
            ],
          },
        },
        {
          name: "数据环比",
          option: {
            title: {
              text: "",
            },
            tooltip: {},
            // legend: {
            //     data:['销量']
            // },
            xAxis: {
              data: [
                "数据一",
                "数据二",
                "数据三",
                "数据四",
                "数据五",
                "数据六",
              ],
            },
            yAxis: {},
            series: [
              {
                name: "销量",
                type: "bar",
                data: [5, 20, 36, 10, 10, 20],
              },
            ],
          },
        },
        {
          name: "校区对比",
          option: {
            title: {
              text: "",
            },
            tooltip: {},
            // legend: {
            //     data:['销量']
            // },
            xAxis: {
              data: [
                "数据一",
                "数据二",
                "数据三",
                "数据四",
                "数据五",
                "数据六",
              ],
            },
            yAxis: {},
            series: [
              {
                name: "销量",
                type: "bar",
                data: [5, 20, 36, 10, 10, 20],
              },
            ],
          },
        },
      ], //财务概况
      employeeProfile: [
        {
          name: "员工概况",
          option: {
            title: {
              text: "",
            },
            tooltip: {},
            // legend: {
            //     data:['销量']
            // },
            xAxis: {
              data: [
                "数据一",
                "数据二",
                "数据三",
                "数据四",
                "数据五",
                "数据六",
              ],
            },
            yAxis: {},
            series: [
              {
                name: "销量",
                type: "bar",
                data: [5, 20, 36, 10, 10, 20],
              },
            ],
          },
        },
      ], //员工概况

      data: ["周杰", "张龙", "仁爱", "朱均", "陈欢"],

      option2: {
        title: {
          text: "",
        },
        tooltip: {},
        // legend: {
        //     data:['销量']
        // },
        xAxis: {
          data: ["数据一", "数据二", "数据三", "数据四", "数据五", "数据六"],
        },
        yAxis: {},
        series: [
          {
            name: "销量",
            type: "bar",
            data: [5, 20, 36, 10, 10, 20],
          },
        ],
      },
      logo: true,
      TransactionRecords: false, //交易记录
      ClassRecord: false, //上课记录
      reviewResults: false, //课堂点评
      news: "给所有学员发送消息", //发送消息
      time: 24, //请假时间限制
      form: {
        name: "",
        type: [],
        region: "家长绑定学员之后的交易记录",
        resource: "",
        resource2: "",
      },
      value1: "",
      value2: "",
      radio1: "",
    };
  },
  methods: {
    displayList(t) {
      this.display_ = t.value;
    },
    RangeChange(item) {
      if (this.dataRangeChart && this.dataRangeChart.length) {
        var p = this.dataRangeChart.filter((x) => {
          return x.name == item.name;
        });
        if (p && p.length) {
          p = this.dataRangeChart.filter((x) => {
            return x.name != item.name;
          });
          this.dataRangeChart = p;
        } else {
          this.dataRangeChart.push(item);
        }
      } else {
        this.dataRangeChart.push(item);
      }
    },

    //图片上传
    changefile(file, b) {
      var _this = this;
      setTimeout(function () {
        if (_this.logo) {
          _this.logoUrl = URL.createObjectURL(file.raw);
        }
      }, 1000);
    },
    handleAvatarSuccess(res, file) {
      this.logoUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type.toLowerCase() === "image/bmp" ||
        file.type.toLowerCase() === "image/png" ||
        file.type.toLowerCase() === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 1;

      if (!isJPG) {
        this.logo = false;
        this.$message.error("上传头像图片只能是 bmp，jpg,png格式!");
      }
      if (!isLt2M) {
        this.logo = false;
        this.$message.error("上传头像图片大小不能超过 1MB!");
      }
      return isJPG && isLt2M;
    },
    //机构二维码
    schoolCode_changefile(file, b) {
      var _this = this;
      setTimeout(function () {
        if (_this.logo) {
          _this.schoolCode = URL.createObjectURL(file.raw);
        }
      }, 1000);
    },
    schoolCode_handleAvatarSuccess(res, file) {
      this.schoolCode = URL.createObjectURL(file.raw);
    },
    schoolCode_beforeAvatarUpload(file) {
      const isJPG =
        file.type.toLowerCase() === "image/bmp" ||
        file.type.toLowerCase() === "image/png" ||
        file.type.toLowerCase() === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.logo = false;
        this.$message.error("上传头像图片只能是 bmp，jpg,png格式!");
      }
      if (!isLt2M) {
        this.logo = false;
        this.$message.error("上传头像图片大小不能超过 1MB!");
      }
      return isJPG && isLt2M;
    },
  },
  resetForm(formName) {
    this.$refs[formName].resetFields();
  },
};
</script>

<style lang="scss">
.color-box {
  color: #0081ff;
  border: 1px solid #8cc5ff;
  background-color: #e8f5ff;
}
.chart {
  background-color: #fff;
  // width: calc(50% - 20px);
  width: 49.5%;
  display: flex;
  justify-content: center;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 750px;
  height: 300px;
  display: block;
}
.schoolCode_avatar {
  width: 300px;
  height: 300px;
  display: block;
}
.configure {
  width: 100%;
  background-color: #fff;
  margin-top: 10px;
  padding: 10px;
}
.title {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  .title-ys {
    border-left: 5px solid rgb(72, 172, 211);
    padding: 0 9px;
  }
}
.status-point {
  background-color: #e6a23c;
  margin-right: 5px;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
</style>